<template>
  <b-card no-body>
    <b-card-body class="text-right">
      <b-form-checkbox
        v-model="onlyUnread"
        switch
        @change="getDataList"
      >
        Okunmuşları Gizle
      </b-form-checkbox>
    </b-card-body>
    <b-table
      responsive="sm"
      :fields="fields"
      :items="dataList"
      striped
    >
      <template #cell(title)="data">
        <b-link @click="goToPage(data.item)">
          <div class="font-small-2 font-weight-bold">
            {{ data.item.title }}
          </div>
          <div class="text-primary text-body">
            {{ data.item.body }}
          </div>
          <div class="text-muted font-small-2">
            {{ moment(data.item.created).format('llll') }}
          </div>
        </b-link>
      </template>
      <template #cell(is_read)="data">
        <b-badge
          v-if="data.item.is_read"
          variant="success"
        >
          Okundu
        </b-badge>
        <b-badge
          v-else
          variant="danger"
        >
          Okunmadı
        </b-badge>
      </template>
    </b-table>
    <b-card-footer>
      <b-pagination
        v-model="currentPage"
        :total-rows="dataCounts"
        :per-page="perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
    </b-card-footer>
  </b-card>
</template>
<script>
import {
  BCard, BCardBody, BTable, BPagination, BLink, BCardFooter, BBadge, BFormCheckbox,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const tableName = 'com_notifications'
export default {
  name: 'NotificationIndex',
  components: {
    BCard,
    BCardBody,
    BTable,
    BPagination,
    BCardFooter,
    BLink,
    BBadge,
    BFormCheckbox,
  },
  data() {
    return {
      onlyUnread: true,
      currentPage: 1,
      perPage: 20,
      fields: [
        {
          key: 'title',
          label: 'BİLDİRİM',
        },
        {
          key: 'is_read',
          label: 'DURUM',
          thClass: 'width-200 text-nowrap',
          tdClass: 'width-200 text-nowrap',
        },
      ],
      dataQuery: {
        select: [
          `${tableName}.id AS id`,
          `${tableName}.title AS title`,
          `${tableName}.body AS body`,
          `${tableName}.item_id AS item_id`,
          `${tableName}.is_read AS is_read`,
          'com_notification_types.route AS route',
          `${tableName}.created AS created`,
        ],
        limit: 20,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['notifications/dataList']
    },
    dataCounts() {
      return this.$store.getters['notifications/dataCounts']
    },
  },
  watch: {
    currentPage(val) {
      this.dataQuery.start = (val - 1) * 20
      this.getDataList()
    },
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
      this.getDataList()
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    goToPage(data) {
      if (!data.is_read) {
        this.$store.dispatch('notifications/saveData', {
          id: data.id,
          read_item: 1,
        }).then(response => {
          if (response) {
            this.$router.push(data.route + data.item_id)
          }
        })
      } else {
        this.$router.push(data.route + data.item_id)
      }
    },
    getDataList() {
      if (this.onlyUnread) {
        this.dataQuery.where = {
          'com_notifications.is_read': null,
        }
      } else {
        this.dataQuery.where = {}
      }
      this.$store.dispatch('notifications/getDataList', this.dataQuery)
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
